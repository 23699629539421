<template>
  <ui-component-modal
    modalTitle="Create new space"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveSpace"
    :isSaving="isSaving"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td width="150">Quantity</td>
            <td>
              <div class="select">
                <select v-model="newSpaceData.Quantity">
                  <option
                    v-for="quantity in quantityOptions"
                    :key="quantity"
                    :value="quantity"
                    :selected="newSpaceData.Quantity === quantity"
                  >
                    {{ quantity }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              <input
                type="text"
                placeholder="Name"
                v-model="newSpaceData.Name"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Width</td>
            <td>
              <input
                @change="calculateArea"
                type="text"
                placeholder="M2"
                v-model.number="newSpaceData.Width"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Length</td>
            <td>
              <input
                @change="calculateArea"
                type="text"
                placeholder="M2"
                v-model.number="newSpaceData.Length"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Height</td>
            <td>
              <input
                type="text"
                placeholder="M2"
                v-model.number="newSpaceData.Height"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Area</td>
            <td>
              <div class="field has-addons">
                <p class="control">
                  <input
                    type="text"
                    placeholder="M2"
                    v-model.number="newSpaceData.M2"
                    class="input"
                  />
                </p>
                <p class="control">
                  <a class="button is-static">M2</a>
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>Floor</td>
            <td>
              <input
                type="text"
                placeholder="Floor"
                v-model="newSpaceData.Floor"
                class="input"
              />
            </td>
          </tr>

          <tr>
            <td>Meeting types</td>
            <td>
              <div>
                <span
                  v-for="meetingtype in locationMeetingtypes"
                  :key="meetingtype.MeetingtypeId"
                  class="has-padding-right"
                >
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      v-model="newSpaceData.Meetingtypes"
                      :value="meetingtype.MeetingtypeId"
                    />
                    {{ meetingtype.MeetingtypeId | getMeetingtypeName }}
                  </label>
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Channels</td>
            <td>
              <div v-if="newSpaceData.Meetingtypes.length > 0">
                <span
                  v-for="channel in channels"
                  :key="channel.ChannelId"
                  class="has-padding-right"
                >
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      v-model="newSpaceData.Channels"
                      :value="channel.ChannelId"
                    />
                    {{ channel.ChannelName }}
                  </label>
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Export group</td>
            <td>
              <div class="select">
                <select v-model="newSpaceData.ExportgroupId">
                  <option
                    :value="0"
                    :selected="newSpaceData.ExportgroupId === 0"
                  >
                    Select an export group
                  </option>
                  <option
                    v-for="exportgroup in exportgroups"
                    :key="exportgroup.Id"
                    :value="exportgroup.Id"
                    :selected="newSpaceData.ExportgroupId === exportgroup.Id"
                  >
                    {{ getExportgroupName(exportgroup.Id) }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table is-fullwidth is-striped is-narrow">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Layout</th>
            <th>Min. seats</th>
            <th>Max. seats</th>
          </tr>
        </thead>
        <tbody name="fade" is="transition-group">
          <tr
            v-for="(config, index) in newSpaceData.Configurations"
            :key="index"
          >
            <td>
              <a @click="deleteConfigurationRow(index)" class="button is-small">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </span>
              </a>
            </td>
            <td>
              <span v-if="config.SettingId > 0">{{
                config.SettingId | getSettingName
              }}</span>
              <span v-if="config.SettingId === 0" class="select">
                <select v-model="config.SettingId">
                  <option
                    v-for="config in filteredSettingIds"
                    :key="config"
                    :value="config"
                  >
                    {{ config | getSettingName }}
                  </option>
                </select>
              </span>
            </td>
            <td>
              <input type="text" class="input" v-model="config.MinSeats" />
            </td>

            <td>
              <input type="text" class="input" v-model="config.MaxSeats" />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>&nbsp;</td>
            <td colspan="3">
              <a
                @click="addConfigurationRow"
                class="button is-small is-primary"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>Add configuration</span>
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import locationProvider from '@/providers/location'
import spaceProvider from '@/providers/space'
import { mapState } from 'vuex'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      exportgroups: [],
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      channelId: Number(this.$route.params.channelId),
      locationId: Number(this.$route.params.locationId),
      locationMeetingtypes: [],
      newSpaceData: this.setNewSpaceData(),
      quantityOptions: [],
      settingIds: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20, 21,
        22, 23, 24,
      ],
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),

    channels() {
      let channels = []
      if (this.locationState) {
        channels = this.locationState.Channels.filter(
          (c) => c.ChannelId === this.channelId
        )
      }
      return channels
    },

    filteredSettingIds() {
      let self = this
      let settingIds = []

      if (self.newSpaceData.Configurations.length > 0) {
        for (let i = 0; i < self.settingIds.length; i++) {
          let settingIndex = self.newSpaceData.Configurations.findIndex(
            (s) => s.SettingId === self.settingIds[i]
          )
          if (settingIndex === -1) {
            settingIds.push(self.settingIds[i])
          }
        }
      }

      return settingIds
    },
  },

  created() {
    this.setQuantityOptions()
    this.getLocationExportgroups()
    this.getLocationMeetingtypes()
  },

  methods: {
    calculateArea() {
      this.newSpaceData.M2 = this.newSpaceData.Length * this.newSpaceData.Height
    },

    setQuantityOptions() {
      for (let i = 1; i < 100; i++) {
        this.quantityOptions.push(i)
      }
    },

    getLocationChannels() {
      if (this.locationState) {
        this.locationChannnels = this.locationState.Channels

        this.newSpaceData.Channels = this.locationChannnels.map(
          (lc) => lc.ChannelId
        )
      }
    },

    getLocationExportgroups() {
      locationProvider.methods
        .getLocationExportgroups(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.exportgroups = response.data
          }
        })
    },

    getExportgroupName(exportgroupId) {
      let name = ''
      let exportgroupIndex = this.exportgroups.findIndex(
        (e) => e.Id === exportgroupId
      )

      if (exportgroupIndex > -1) {
        name = this.exportgroups[exportgroupIndex].Name
      }
      return name
    },

    getLocationMeetingtypes() {
      if (this.locationState) {
        this.locationMeetingtypes = this.locationState.Meetingtypes
      }
    },

    addConfigurationRow() {
      let self = this
      self.newSpaceData.Configurations.push({
        SettingId: 0,
        MinSeats: 1,
        MaxSeats: 1,
      })
    },

    deleteConfigurationRow(index) {
      let self = this

      self.newSpaceData.Configurations.splice(index, 1)
    },

    setNewSpaceData() {
      return {
        LocationId: Number(this.$route.params.locationId),
        Name: '',
        Quantity: 1,
        Floor: 0,
        Channels: [],
        Meetingtypes: [],
        ExportgroupId: 0,
        Width: 0,
        Length: 0,
        Height: 0,
        M2: 0,
        Configurations: [],
      }
    },

    saveSpace() {
      this.isSaving = true
      this.isSavingSuccess = false

      spaceProvider.methods
        .createSpace(this.newSpaceData)
        .then((response) => {
          if (response.status === 200) {
            this.$emit('dataSaved')
            this.onClickCancel()
          }
        })
        .catch((error) => {
          //console.log(error.response)
          this.isSavingError = true
        })
        .finally(() => {
          this.isSaving = false
          this.isSavingSuccess = true
        })
    },
  },
}
</script>
